.post-survey-messsage {
  @include font-150p-wt-regular;
}

.survey-form-container {
  padding-bottom: 100px;
  @include no-padding("x");
}

.survey-form-action-buttons {
  margin-top: 1rem;
  @include no-padding("x");
}

.survey-intro-text {
  line-height: 1.5;
  margin-top: 1.5rem;
  font-size: 1.25rem;
  font-weight: 300;
}
