* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: Roboto, Helvetica, sans-serif;
}

.app-container {
  max-width: 1400px;
  min-width: 360px;
  margin: auto;
  padding: 0 2rem;
  height: 100vh;
  width: auto;
}

.logo {
  margin: 5px auto;
  border-radius: 5px;
  transition: height 0.2s;

  &.logo-fixed-height {
    width: auto;
    height: 50px;
  }

  &.logo-fixed-width {
    height: auto;
    width: 150px;
  }

  &.logo-fixed-extra-width {
    height: auto;
    width: 200px;
  }

  &.logo-contained {
    object-fit: contain;
  }

  &.shorten-logo {
    margin: auto;
    height: 18px;
  }
}

.org-name {
  @include font-150p-wt-regular;

  &.mobile-view {
    font-size: 1.25rem;
    font-weight: 500;
    transition: font-size 0.2s;

    &.shorten-org-name {
      font-size: 1rem;
    }
  }
}

.container-center {
  @include justified-container();

  &.column {
    flex-direction: column;
  }
}

.container-space-between {
  @include justified-container(space-between);
}

.no-x-padding {
  @include no-padding("x");
}

.no-y-padding {
  @include no-padding("y");
}

.no-padding {
  @include no-padding();
}

.my-1r {
  @include margin-rem("y");
}

// .gradient-bg {
//   // height: 100vh;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-attachment: fixed;
//   background-image: url("../../img/background.png") !important;

//   &.dark-gradient {
//     background-image: url("../../img/background-dark.png") !important;
//   }
// }
