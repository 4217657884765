@mixin font-150p-wt-regular {
  font-size: 1.5rem;
  font-weight: 400;
}

@mixin no-padding($direction: "both") {
  @if ($direction == "both" or $direction == "x") {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @if ($direction == "both" or $direction == "y") {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@mixin justified-container($option: center) {
  display: flex;
  justify-content: $option;
  align-items: center;
  width: 100%;
  height: 100%;
}

@mixin margin-rem($direction: "xy", $remSizeX: 1rem, $remSizeY: 1rem) {
  @if ($direction == "x") {
    margin: auto $remSizeX;
  }

  @if ($direction == "y") {
    // used first parameter for size
    margin: $remSizeX auto;
  }

  @if ($direction == "xy") {
    margin: $remSizeY $remSizeX;
  }
}
